import { useEffect, useState, useCallback } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import '../Assets/css/Main.css'
import base from '../Services/BaseService'
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';


const FileModal = ({ showModalForm, closeModal, tokenRefresh }) => {
    const [showModal, setShowModal] = useState(showModalForm)
    const [previewClick, setPreviewClick] = useState(false)
    // const [selectedFile, setSelectedFile] = useState(null);
    const [previewData, setPreviewData] = useState([]);
    const [importColDef, setImportColDef] = useState([]);
    const [gridDefaultColDef, setGridDefaultColDef] = useState([]);

    const toggleModal = () => {
        closeModal()
        setShowModal(showModal => !showModal);
    }

    const formatNumber = useCallback((number) => {
        // this puts commas into the number eg 1000 goes to 1,000,
        return (number)
            .toFixed(2)
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$&,');
    }, []);

    const currencyFormatter = useCallback((params) => {
        return formatNumber(params.value);
        // return params.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    }, [formatNumber]);



    const initGridVar = useCallback(() => {
        setGridDefaultColDef({
            flex: 1,
            minWidth: 100,
            sortable: true,
            filter: true,
            resizable: false,
            editable: false,
            sortingOrder: ["asc", "desc"],
        });

        setImportColDef([

            {
                headerName: "Account",
                field: "bank",
                // cellClass: "alignCenter widthhhh",
                suppressMovable: true,
                maxWidth: 150
            },
            {
                headerName: "Date",
                field: "textDate",
                // cellClass: "alignCenter widthhhh",
                suppressMovable: true,
                maxWidth: 125
            },
            {
                headerName: "Description",
                field: "reason1",
                // cellClass: "alignCenter widthhhh",
                suppressMovable: true,
                maxWidth: 500
            },
            {
                headerName: "Category",
                field: "category",
                // cellClass: "alignCenter widthhhh",
                suppressMovable: true,
                maxWidth: 125
            },
            {
                headerName: "Amount",
                field: "value",
                suppressMovable: true,
                filter: 'agNumberColumnFilter',
                type: 'numericColumn',
                maxWidth: 300,
                valueFormatter: currencyFormatter
            },
        ]
        );

    }, [currencyFormatter]);

    useEffect(() => {
        initGridVar();
    }, [initGridVar]);

    const UploadClick = async () => {
        // setPreviewClick(!previewClick);
        // alert("Upload Clicked");
        await tokenRefresh()
        base.add("FileUpload/UploadCommit", "").then((res) => {
            toggleModal()
            // setPreviewData(res);
            // console.log(res);
            // setPreviewClick(!previewClick);
        }).catch((e) => {
            console.log("Error acquiring token")
        })
    };

    const handleFileInput = async (e) => {
        // handle validations
        await tokenRefresh()
        const file = e.target.files[0];
        var data = new FormData();
        data.append('file', file);
        base.add("FileUpload/Add", data).then((res) => {
            setPreviewData(res.data);
            // console.log(res);
            setPreviewClick(!previewClick);
        });


        // alert(file.value);
        // if (file.size > 1024)
        //     onFileSelectError({ error: "File size cannot exceed more than 1MB" });
        // else onFileSelectSuccess(file);
    };

    return (
        <>
            <Modal isOpen={showModal} size="xl" toggle={() => setShowModal(!showModal)} backdrop="static">
                <ModalHeader className="page-header" toggle={() => setShowModal(!showModal)}><div className="md-header">Import Data</div></ModalHeader>

                <ModalBody>
                    {previewClick ?
                        <div className="ag-theme-balham" style={{ height: "390px", width: "100%" }} >
                            <AgGridReact
                                rowData={previewData}
                                columnDefs={importColDef}
                                overlayNoRowsTemplate="No Records Found"
                                defaultColDef={gridDefaultColDef}
                                pagination={true}
                                paginationPageSize={10}>
                            </AgGridReact>
                        </div>
                        :
                        <form>
                            <input className='input-file'
                                type="file"
                                // value={selectedFile}
                                onChange={handleFileInput}
                            />
                        </form>
                    }
                </ModalBody>
                <ModalFooter>
                    {previewClick &&
                        <Button color="secondary" size="sm" className="btn-text" onClick={() => UploadClick()}>Upload</Button>
                        // <Button color="brand-secondary" size="sm" className="b-width rounded-pill" onClick={() => PreviewClick()}>Preview</Button>
                    }
                </ModalFooter>

            </Modal>
        </>
    )
}

export default FileModal
