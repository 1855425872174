import React from 'react'
// import logo from './logo.svg';
import { useState } from 'react';
import '../App.css';
import '../Assets/css/Main.css'
import { Col, Row, Button, Label, Input, Modal, ModalBody, ModalHeader, FormGroup, ModalFooter } from 'reactstrap';
// import DataTable from 'react-data-table-component';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import base from '../Services/BaseService'
// import LoadSpinner from './Shared/Loading'
// import { useMsal } from "@azure/msal-react";
// import { loginRequest } from '../authconfig';

const BudgetModal1 = ({ closeModal1, showModalForm1, setShowModal1, setSaveItem }) => {
    // console.log("I am here")
    const periodValue = new Date().getFullYear() + '.' + ("0" + (new Date().getMonth() + 1)).slice(-2);
    const [pName, setPName] = useState(periodValue)
    // const [cName, setCName] = useState()
    // const [eName, setEName] = useState()
    // const [vName, setVName] = useState()
    // const [loading, setLoading] = useState(false)
    const [showModal, setShowModal] = useState(showModalForm1)
    // const { instance, accounts } = useMsal();
    const budgetData = {};

    const toggleModal = () => {
        closeModal1()
        setShowModal(showModal => !showModal);
        setShowModal1(!showModalForm1);
    }

    const updateBudget = () => {
        budgetData.period = pName;
        // budgetData.category = cName;
        // budgetData.expense = eName;
        // budgetData.value = vName;
        // base.update("Budget/" + id, budgetData).then((res) => {
        base.update("Budget/Create", budgetData).then((res) => {
            toggleModal()
            setSaveItem(new Date().getTime());
        })
        // alert("Save!")
    }

    const handleChange = (e) => {
        if (e.target.name === 'period') {
            setPName(e.target.value);
            // setDescriptionRemaining(e.target.value.length);
        }

        document.getElementById(e.target.id).style.border = '1px solid #cecece';
    }

    // const tokenRefresh = useCallback(async () => {
    //     await instance.acquireTokenSilent({
    //         ...loginRequest,
    //         account: accounts[0]
    //     }).then((response) => {
    //         // console.log("token has been added:2")
    //         sessionStorage.setItem("upn", accounts[0].idTokenClaims.upn);
    //         sessionStorage.setItem("username", accounts[0].idTokenClaims.name);
    //         sessionStorage.setItem("authheader", "Bearer " + response.idToken);
    //         //console.log(response)
    //     }).catch((e) => {
    //         console.log("Error acquiring token")
    //     }
    //     );
    // }, [accounts, instance])


    return (
        <>
            <Modal isOpen={showModal} size="lg" toggle={toggleModal} backdrop="static">
                <ModalHeader className="bg-modal" toggle={toggleModal}><div className="page-header">Create Budget Period</div></ModalHeader>
                {/* {loading ? */}
                {/* <LoadSpinner /> */}
                <>
                    <ModalBody>
                        <FormGroup>
                            <Row className="mb-2 align-items-center">
                                <Col md="3" lg="2">
                                    <Label className="label-text" >Period*</Label>
                                </Col>
                                <Col md="9" lg="10">
                                    <Input type="text" className="input-text" name="period" id="period" placeholder="" defaultValue={periodValue} onChange={handleChange} />
                                </Col>
                            </Row>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="success" size="sm" className="btn-text b-width" onClick={() => updateBudget()}>Create</Button>
                        <Button color="secondary" size="sm" className="btn-text b-width" onClick={toggleModal}>Cancel</Button>
                    </ModalFooter>
                </>

            </Modal>
        </>
    )
}

export default BudgetModal1
