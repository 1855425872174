import { useState } from 'react';
import '../../Assets/css/Navbar.css'
import { Navbar, NavbarBrand, Collapse, Nav, NavItem, NavbarToggler } from 'reactstrap'
import { useMsal } from "@azure/msal-react";
import { Link } from 'react-router-dom';
// import Button from "react-bootstrap/Button";

const Navibar = (props) => {

    const { instance } = useMsal();

    function handleLogout() {
        instance.logoutRedirect().catch(e => {
            console.error(e);
        });
    }

    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    return (
        <Navbar className="bg-Nav" dark expand="md">
            <NavbarBrand className="nav-brand" href="/">Income Management Tool</NavbarBrand>
            {!props.error &&
                <>
                    <NavbarToggler onClick={toggle} />
                    <Collapse isOpen={isOpen} navbar>
                        <Nav className="ms-auto me-4" navbar>
                            <NavItem className="mt-2 me-3">
                                <Link to='/' className="nav-text" >
                                    <span>Dashboard &nbsp;&nbsp;
                                    </span>
                                </Link>
                            </NavItem>
                            <NavItem className="mt-2 me-3">
                                <Link to='/budget' className="nav-text" >
                                    <span>Budgets &nbsp;&nbsp;
                                    </span>
                                </Link>
                            </NavItem>
                            <NavItem className="mt-2 me-3">
                                <Link to='/category' className="nav-text" >
                                    <span>Categories &nbsp;&nbsp;
                                    </span>
                                </Link>
                            </NavItem>
                            <NavItem className="mt-2 me-3">
                                <Link to='/investment' className="nav-text" >
                                    <span>Investments &nbsp;&nbsp;
                                    </span>
                                </Link>
                            </NavItem>
                            {/* <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle nav>
                            <span className="nav-text" >Admin
                            </span><img src={down16} alt="" />

                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem header style={{ fontSize: "14px !important", letterSpacing: "0px !important" }}>Account</DropdownItem>
                            <Link to='/MyProfile' className="nav-text"><DropdownItem>My Profile</DropdownItem></Link>
                            <DropdownItem divider />
                            <DropdownItem header style={{ fontSize: "14px !important", letterSpacing: "0px !important" }}>Issue</DropdownItem>
                            <Link to='/Approve' className="nav-text"><DropdownItem>Publish Contributions</DropdownItem></Link>
                        </DropdownMenu>
                    </UncontrolledDropdown> */}
                            {/* <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle nav>
                                    <span className="nav-text">Manage</span><img src={down16} alt="" />
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem header style={{ fontSize: "14px !important", letterSpacing: "0px !important" }}>Savings</DropdownItem>
                                    <Link to='/Accounts' className="nav-text-2"><DropdownItem><span className="nav-text-2">Accounts</span></DropdownItem></Link>
                                    <Link to='/Budget' className="nav-text-2"><DropdownItem><span className="nav-text-2">Budgets</span></DropdownItem></Link>
                                    <DropdownItem divider />
                                    <Link to='/usererror' className="nav-text-2"><DropdownItem><span className="nav-text-2">Share ISA</span></DropdownItem></Link>
                                </DropdownMenu>
                            </UncontrolledDropdown> */}


                            <NavItem className="mt-2" style={{ cursor: "pointer" }} >
                                <span onClick={handleLogout} className="nav-text">Logout
                                </span>
                            </NavItem>
                        </Nav>
                    </Collapse>
                </>
            }
        </Navbar>
    );
}

export default Navibar