import React from 'react'
// import logo from './logo.svg';
import { useEffect, useState, useCallback } from 'react';
import '../App.css';
import '../Assets/css/Main.css'
import { Col, Row, Button, Label, Input, Modal, ModalBody, ModalHeader, FormGroup, ModalFooter } from 'reactstrap';
// import DataTable from 'react-data-table-component';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import base from '../Services/BaseService'
import LoadSpinner from './Shared/Loading'
import { useMsal } from "@azure/msal-react";
import { loginRequest } from '../authconfig';

const CategoryModal = ({ id, closeModal, showModalForm, setSaveItem }) => {

    const [pName, setPName] = useState()
    const [cName, setCName] = useState()
    const [eName, setEName] = useState()
    // const [vName, setVName] = useState()
    const [loading, setLoading] = useState(false)
    const [showModal, setShowModal] = useState(showModalForm)
    const { instance, accounts } = useMsal();
    const budgetData = {};

    const toggleModal = () => {
        closeModal()
        setShowModal(showModal => !showModal);
    }


    const updateBudget = () => {
        budgetData.category = pName;
        budgetData.cat_Desc = cName;
        budgetData.defaultAmount = eName;
        // budgetData.value = vName;
        if (id === "new") {
            base.add("Category/add", budgetData).then((res) => {
                toggleModal()
                setSaveItem(new Date().getTime());
            })
        }
        else {
            base.update("Category/" + id, budgetData).then((res) => {
                toggleModal()
                setSaveItem(new Date().getTime());
            })
        }
        // alert("Save!")
    }

    const deleteBudget = () => {
        budgetData.category = pName;
        budgetData.cat_Desc = cName;
        budgetData.defaultAmount = eName;
        console.log("delete called");
        base.update("Category/del/" + id, "").then((res) => {
            toggleModal()
            setSaveItem(new Date().getTime());
        })
        // alert("Save!")
    }

    const handleChange = (e) => {
        if (e.target.name === 'category') {
            setPName(e.target.value);
            // setDescriptionRemaining(e.target.value.length);
        }
        if (e.target.name === 'cat_Desc') {
            setCName(e.target.value)
            // setHeadRemaining(e.target.value.length);
        }
        if (e.target.name === 'defaultAmount') {
            setEName(e.target.value)
            // setHeadRemaining(e.target.value.length);
        }

        document.getElementById(e.target.id).style.border = '1px solid #cecece';
    }

    const tokenRefresh = useCallback(async () => {
        await instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0]
        }).then((response) => {
            // console.log("token has been added:2")
            sessionStorage.setItem("upn", accounts[0].idTokenClaims.upn);
            sessionStorage.setItem("username", accounts[0].idTokenClaims.name);
            sessionStorage.setItem("authheader", "Bearer " + response.idToken);
            //console.log(response)
        }).catch((e) => {
            console.log("Error acquiring token")
        }
        );
    }, [accounts, instance])

    useEffect(() => {
        const getBudgetItem = () => {
            tokenRefresh()
            // if (editExisting) {
            // setDisableUPN(dU => true)
            if (id !== "new") {
                base.getAll("Category/" + id).then((res) => {
                    setPName(res.category);
                    setCName(res.cat_Desc);
                    setEName(res.defaultAmount);
                    // setDName(res.cat_Desc);
                    // setVName(res.value);

                })
            }
            setLoading(false)
            // }
            // else {
            //     // setDisableUPN(dU => false)
            //     setLoading(false)
            // }
        }
        getBudgetItem()
    }, [tokenRefresh, id])


    return (
        <>
            <Modal isOpen={showModal} size="lg" toggle={toggleModal} backdrop="static">
                <ModalHeader className="bg-modal" toggle={toggleModal}><div className="page-header">Edit Budget Item</div></ModalHeader>
                {loading ?
                    <LoadSpinner />
                    :
                    <>
                        <ModalBody>
                            <FormGroup>
                                <Row className="mb-2 align-items-center">
                                    <Col md="3" lg="2">
                                        <Label className="label-text" >Category*</Label>
                                    </Col>
                                    <Col md="9" lg="10">
                                        <Input type="text" className="input-text" name="category" id="category" placeholder="" defaultValue={pName} onChange={handleChange} />
                                    </Col>
                                </Row>
                                <Row className="mb-2 align-items-center">
                                    <Col md="3" lg="2">
                                        <Label className="label-text">Description*</Label>
                                    </Col>
                                    <Col md="9" lg="10">
                                        <Input type="text" className="input-text" name="cat_Desc" id="cat_Desc" placeholder="" defaultValue={cName} onChange={handleChange} />
                                    </Col>
                                </Row>
                                <Row className="mb-2 align-items-center">
                                    <Col md="3" lg="2">
                                        <Label className="label-text">Default Amount*</Label>
                                    </Col>
                                    <Col md="9" lg="10">
                                        <Input type="text" className="input-text" name="defaultAmount" id="defaultAmount" placeholder="" defaultValue={eName} onChange={handleChange} />
                                    </Col>
                                </Row>
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="success" size="sm" className="btn-text b-width" onClick={() => updateBudget()}>Save</Button>
                            {id !== "new" &&
                                <>
                                    <Button color="danger" size="sm" className="btn-text b-width" onClick={() => deleteBudget()}>Delete</Button>
                                </>
                            }
                            <Button color="secondary" size="sm" className="btn-text b-width" onClick={toggleModal}>Cancel</Button>
                        </ModalFooter>
                    </>
                }
            </Modal>
        </>
    )
}

export default CategoryModal
