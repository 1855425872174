import React from 'react'
// import logo from './logo.svg';
import { useEffect, useState, useCallback } from 'react';
import '../App.css';
import '../Assets/css/Main.css'
import { Col, Row, Button, Label, Input, Modal, ModalBody, ModalHeader, FormGroup, ModalFooter } from 'reactstrap';
// import DataTable from 'react-data-table-component';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import base from '../Services/BaseService'
import LoadSpinner from './Shared/Loading'
import { useMsal } from "@azure/msal-react";
import { loginRequest } from '../authconfig';

const BudgetModal = ({ id, closeModal, showModalForm, setSaveItem, isEdit }) => {

    const [vName, setVName] = useState("")
    const [pName, setPName] = useState("")
    const [bName, setBName] = useState("")
    const [fName, setFName] = useState("")
    const [fieldDisbaled, setFieldDisabled] = useState(!isEdit)
    const [loading, setLoading] = useState(false)
    const [showModal, setShowModal] = useState(showModalForm)
    const { instance, accounts } = useMsal();
    const investmentData = {};

    const toggleModal = () => {
        closeModal()
        setShowModal(showModal => !showModal);
    }

    const updateInvestment = () => {
        investmentData.fund_Valuation = vName;
        investmentData.fund_Price = pName;
        investmentData.book_Cost = bName;
        investmentData.fund_Name = fName;
        // investmentData.value = vName;

        if (isEdit) {
            base.update("Investment/" + id, investmentData).then((res) => {
                toggleModal()
                setSaveItem(new Date().getTime());
            })
        }
        else {
            base.add("Investment", investmentData).then((res) => {
                toggleModal()
                setSaveItem(new Date().getTime());
            })

        }
        // alert("Save!")
    }

    const handleChange = (e) => {
        if (e.target.name === 'valuation') {
            setVName(e.target.value);
            // setDescriptionRemaining(e.target.value.length);
        }
        if (e.target.name === 'price') {
            setPName(e.target.value)
            // setHeadRemaining(e.target.value.length);
        }
        if (e.target.name === 'book') {
            setBName(e.target.value)
            // setHeadRemaining(e.target.value.length);
        }

        if (e.target.name === 'fund_Name') {
            setFName(e.target.value)
            // investmentData.fund_Name = fName;
            // setFName(res.fund_Name);
            if (e.target.value !== "") {
                base.getAll("Investment/ShareISA/" + e.target.value).then((inv) => {
                    setVName(inv.fund_Valuation);
                    setPName(inv.fund_Price);
                    setBName(inv.book_Cost);
                    // console.log(inv.fund_Valuation)
                    setFieldDisabled(false)
                }
                )
            }
            // base.getAll("Investment/513").then((res) => {
            //     setVName(res.fund_Valuation);
            //     setPName(res.fund_Price);
            //     setBName(res.book_Cost);
            //     setFName(res.fund_Name);
            //     // setDName(res.cat_Desc);
            //     // setVName(res.value);
            //     console.log(res.fund_Valuation)

            // })
            // setHeadRemaining(e.target.value.length);
        }

        document.getElementById(e.target.id).style.border = '1px solid #cecece';
    }

    const tokenRefresh = useCallback(async () => {
        await instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0]
        }).then((response) => {
            // console.log("token has been added:2")
            sessionStorage.setItem("upn", accounts[0].idTokenClaims.upn);
            sessionStorage.setItem("username", accounts[0].idTokenClaims.name);
            sessionStorage.setItem("authheader", "Bearer " + response.idToken);
            //console.log(response)
        }).catch((e) => {
            console.log("Error acquiring token")
        }
        );
    }, [accounts, instance])

    const deleteBudget = () => {
        // console.log("delete called");
        base.update("Investment/del/" + id, "").then((res) => {
            toggleModal()
            setSaveItem(new Date().getTime());
        })
        // alert("Save!")
    }

    useEffect(() => {
        const getInvestmentItem = () => {
            tokenRefresh()
            // if (editExisting) {
            // setDisableUPN(dU => true)
            if (isEdit) {
                base.getAll("Investment/" + id).then((res) => {
                    setVName(res.fund_Valuation);
                    setPName(res.fund_Price);
                    setBName(res.book_Cost);
                    setFName(res.fund_Name);
                    // setDName(res.cat_Desc);
                    // setVName(res.value);

                })
            }
            setLoading(false)
            // }
            // else {
            //     // setDisableUPN(dU => false)
            //     setLoading(false)
            // }
        }
        getInvestmentItem()
    }, [tokenRefresh, id, isEdit])


    return (
        <>
            <Modal isOpen={showModal} size="lg" toggle={toggleModal} backdrop="static">
                <ModalHeader className="bg-modal" toggle={toggleModal}><div className="page-header">{isEdit ? "Edit Investment Item" : "Create Investment Item"}</div></ModalHeader>
                {loading ?
                    <LoadSpinner />
                    :
                    <>
                        <ModalBody>
                            <FormGroup>
                                <Row className="mb-2 align-items-center">
                                    <Col md="3" lg="2">
                                        <Label className="label-text">Fund Name*</Label>
                                    </Col>
                                    <Col md="9" lg="10">
                                        <Input type="select" name="fund_Name" id="fund_Name" className='input-text' value={fName} onChange={handleChange} >
                                            <option value={null} className='inputselect'>
                                            </option>
                                            <option className='input-text' value="Cash">
                                                Cash
                                            </option>
                                            <option className='input-text' value="FTSE Developed Europe UCITS ETF (VEUR)">
                                                FTSE Developed Europe UCITS ETF (VEUR)
                                            </option>
                                            <option className='input-text' value="Global Equity Fund - Accumulation">
                                                Global Equity Fund - Accumulation
                                            </option>
                                            <option className='input-text' value="LifeStrategy Equity Fund">
                                                LifeStrategy Equity Fund
                                            </option>
                                            <option className='input-text' value="S&P 500 UCITS ETF (VUSA)">
                                                S&P 500 UCITS ETF (VUSA)
                                            </option>
                                        </Input>
                                    </Col>
                                </Row>
                                <Row className="mb-2 align-items-center">
                                    <Col md="3" lg="2">
                                        <Label className="label-text">Invested*</Label>
                                    </Col>
                                    <Col md="9" lg="10">
                                        <Input type="text" className="input-text" name="book" id="book" placeholder="" disabled={fieldDisbaled} value={bName} onChange={handleChange} />
                                    </Col>
                                </Row>
                                <Row className="mb-2 align-items-center">
                                    <Col md="3" lg="2">
                                        <Label className="label-text">Fund Price*</Label>
                                    </Col>
                                    <Col md="9" lg="10">
                                        <Input type="text" className="input-text" name="price" id="price" placeholder="" disabled={fieldDisbaled} value={pName} onChange={handleChange} />
                                    </Col>
                                </Row>
                                <Row className="align-items-center">
                                    <Col md="3" lg="2">
                                        <Label className="label-text" >Fund Valuation*</Label>
                                    </Col>
                                    <Col md="9" lg="10">
                                        <Input type="text" className="input-text" name="valuation" id="valuation" placeholder="" disabled={fieldDisbaled} value={vName} onChange={handleChange} />
                                    </Col>
                                </Row>
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="success" size="sm" className="btn-text b-width" onClick={() => updateInvestment()}>Save</Button>
                            {isEdit &&
                                <Button color="danger" size="sm" className="btn-text b-width" onClick={() => deleteBudget()}>Delete</Button>
                            }
                            <Button color="secondary" size="sm" className="btn-text b-width" onClick={toggleModal}>Cancel</Button>
                        </ModalFooter>
                    </>
                }
            </Modal>
        </>
    )
}

export default BudgetModal
