import React from 'react'
// import logo from './logo.svg';
import { useEffect, useState, useCallback, useRef } from 'react';
import '../App.css';
import '../Assets/css/Main.css'
import { Col, Row, CardHeader, CardBody, Button } from 'reactstrap';
// import DataTable from 'react-data-table-component';
import add from '../Assets/icons/addlrg24.svg'
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import base from '../Services/BaseService'
import LoadSpinner from './Shared/Loading'
import { useMsal } from "@azure/msal-react";
import { loginRequest } from '../authconfig';
import BudgetModal from './BudgetModal'
import BudgetModal1 from './BudgetModal1'

const Budget = () => {

    const gridRef = useRef();
    const [budgetData, setBudgetData] = useState([]);
    const [saveItem, setSaveItem] = useState();
    const [budgetID, setBudgetID] = useState();
    const [gridDefaultColDef, setGridDefaultColDef] = useState([]);
    const [budgetColDef, setBudgetColDef] = useState([]);
    const [showModal, setShowModal] = useState(false)
    const [showModal1, setShowModal1] = useState(false)
    const [toggleModal, setToggleModal] = useState(false)
    const [toggleModal1, setToggleModal1] = useState(false)
    const [expLoading, setExpLoading] = useState(true);
    const { instance, accounts } = useMsal();

    const formatNumber = useCallback((number) => {
        // this puts commas into the number eg 1000 goes to 1,000,
        return (number)
            .toFixed(2)
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$&,');
    }, []);
    const currencyFormatter = useCallback((params) => {
        return formatNumber(params.value);
        // return params.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    }, [formatNumber]);




    const initGridVar = useCallback(() => {
        setGridDefaultColDef({
            flex: 1,
            minWidth: 100,
            sortable: true,
            filter: true,
            resizable: false,
            editable: false,
            sortingOrder: ["asc", "desc"],
        });

        setBudgetColDef([
            {
                headerName: "Period",
                field: "period",
                // cellClass: "alignCenter widthhhh",
                suppressMovable: true,
                maxWidth: 150
            },
            {
                headerName: "Category",
                field: "category",
                // cellClass: "alignCenter widthhhh",
                suppressMovable: true,
                maxWidth: 150
            },
            {
                headerName: "Category Description",
                field: "cat_Desc",
                // cellClass: "alignCenter widthhhh",
                suppressMovable: true,
                maxWidth: 500
            },
            {
                headerName: "Description",
                field: "expense",
                // cellClass: "alignCenter widthhhh",
                suppressMovable: true,
                maxWidth: 500
            },
            {
                headerName: "Amount",
                field: "value",
                suppressMovable: true,
                filter: 'agNumberColumnFilter',
                type: 'numericColumn',
                maxWidth: 300,
                valueFormatter: currencyFormatter
            },
            {
                headerName: "id",
                field: "id",
                // hidden: true,
                suppressMovable: true,
                hide: true,
                // filter: 'agNumberColumnFilter',
                type: 'numericColumn',
                // setColumnVisible: false,
                // valueFormatter: currencyFormatter
            },
        ]
        );
    }, [currencyFormatter]);


    const openModal = () => {
        setShowModal(!showModal)
        // console.log(showModal)
    }

    const openModal1 = () => {
        setShowModal1(!showModal1)
        // console.log(showModal)
    }

    const closeModal = () => {
        setShowModal(showModal => false)
        // setExpLoading(true)
        setToggleModal(!toggleModal)
    }

    const closeModal1 = () => {
        setShowModal1(showModal1 => false)
        // setExpLoading(true)
        setToggleModal1(!toggleModal1)
    }

    useEffect(() => {
        initGridVar();
    }, [initGridVar]);

    const tokenRefresh = useCallback(async () => {
        await instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0]
        }).then((response) => {
            // console.log("token has been added:2")
            sessionStorage.setItem("upn", accounts[0].idTokenClaims.upn);
            sessionStorage.setItem("username", accounts[0].idTokenClaims.name);
            sessionStorage.setItem("authheader", "Bearer " + response.idToken);
            //console.log(response)
        }).catch((e) => {
            console.log("Error acquiring token")
        }
        );
    }, [accounts, instance])

    useEffect(() => {
        const getBudgetData = async () => {
            await base.getAll("Budget").then((budgetRes) => {
                setBudgetData(budgetRes);
            });
        }
        tokenRefresh().then(() => {
            getBudgetData();
            setTimeout(() => {
                setExpLoading(false)
            }, [1000])
        })

    }, [saveItem, tokenRefresh])


    const onSelectionChanged = () => {
        const selectedRows = gridRef.current.api.getSelectedRows();
        if (selectedRows.length === 1) {
            setBudgetID(selectedRows[0].id);
            openModal();
            // console.log(selectedRows[0].id)
            // setTimeout(() => {
            //     console.log(budgetID);
            // }, [5000])

        }
        // document.querySelector('#selectedRows').innerHTML =
        //     selectedRows.length === 1 ? selectedRows[0].id : '';
    };

    return (
        <>
            {/* <Row className="grid-margin">
                <Col xs="12"> */}
            <CardHeader className="pt-3 pb-3">
                <Row>
                    <Col xs="6" className="page-header">
                        Budgets
                    </Col>
                    {/* <span > */}
                    <Col xs="6" className="text-end pe-4">
                        <Button size="sm" color="dark" className="btn-text" onClick={openModal1} >
                            <img src={add} alt="add" />
                        </Button>
                    </Col>
                    {/* </span> */}
                </Row>
            </CardHeader>
            {expLoading ?
                <LoadSpinner />
                :
                <div style={{ marginBottom: "80px" }}>
                    <div className="pt-2 pb-2 res-padding scroll-container">
                        {/* <div className="example-header">
                                Selection:
                                <span id="selectedRows"></span>
                            </div> */}

                        <CardBody style={{ height: "700px" }}>
                            <div className="ag-theme-balham" style={{ height: "100%", width: "100%" }} >
                                <AgGridReact
                                    ref={gridRef}
                                    rowData={budgetData}
                                    columnDefs={budgetColDef}
                                    overlayNoRowsTemplate="No Records Found"
                                    defaultColDef={gridDefaultColDef}
                                    pagination={true}
                                    // onGridReady={onGridReady}
                                    rowSelection={'single'}
                                    // rowSelection={'single'}
                                    paginationPageSize={20}
                                    // gridOptions={gridOptions}
                                    onRowDoubleClicked={onSelectionChanged}
                                // onSelectionChanged={onSelectionChanged}
                                // onGridReady={onGridReady}
                                // onSelectionChanged={gridRef.current.api.getSelectedRows()}
                                // onRowDoubleClicked={() => console.log('Row double clicked')}
                                // onRowDoubleClicked=(()=> editRow())
                                >
                                </AgGridReact>
                            </div>
                        </CardBody>
                    </div>
                </div>
            }

            {/* </Col>

            </Row > */}
            {showModal1 && <BudgetModal1 closeModal1={closeModal1} showModalForm1={true} setShowModal1={setShowModal1} tokenRefresh={tokenRefresh} setSaveItem={setSaveItem} />}

            {showModal && <BudgetModal id={budgetID} closeModal={closeModal} showModalForm={true} tokenRefresh={tokenRefresh} setSaveItem={setSaveItem} />}
            {/* {showModal && <BudgetModal id={budgetID} openModal={openModal} closeModal={closeModal} showModalForm={true} />} */}

        </>
    )
}

export default Budget
