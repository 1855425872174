// import logo from './logo.svg';
import { useEffect, useState, useCallback } from 'react';
import '../App.css';
import '../Assets/css/Main.css'
import { Col, Row, Card, CardHeader, CardBody, Button } from 'reactstrap';
// import DataTable from 'react-data-table-component';
import upload from '../Assets/icons/upload24.svg'
import pound from '../Assets/icons/pound24.svg'
import refresh from '../Assets/icons/refresh24.svg'
import poundcircle from '../Assets/icons/poundcircle24.svg'
import grid from '../Assets/icons/grid24.svg'
import chart from '../Assets/icons/Chart24.svg'
import cvc from '../Assets/icons/cvc24.svg'
// import { AgChartsReact } from 'ag-charts-react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import base from '../Services/BaseService'
import FileModal from './FileModal';
import LoadSpinner from './Shared/ComponentLoading'
import { useMsal } from "@azure/msal-react";
import { loginRequest } from '../authconfig';
import { Bar, Line } from 'react-chartjs-2';
import {
    Chart as ChartJS, CategoryScale,
    LinearScale,
    BarElement, LineElement, PointElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement, PointElement, LineElement,
    ChartDataLabels,
    Title,
    Tooltip,
    Legend
);


const Dashboard = () => {

    const [fieldData, setFieldData] = useState({});

    const [budgetData, setBudgetData] = useState([]);
    const [expData, setExpData] = useState([]);
    // const [investmentData, setInvestmentData] = useState([]);
    const [investmentValues, setInvestmentValues] = useState([]);
    const [investmentValues1, setInvestmentValues1] = useState([]);
    const [investmentValues2, setInvestmentValues2] = useState([]);
    const [investmentValues3, setInvestmentValues3] = useState([]);
    const [investmentValues4, setInvestmentValues4] = useState([]);
    const [investmentLabels, setInvestmentLabels] = useState([]);
    // const [bankData, setBankData] = useState([]);
    const [bankValues, setBankValues] = useState([]);
    const [bankLabels, setBankLabels] = useState([]);
    const [transColDef, setTransColDef] = useState([]);
    const [gridDefaultColDef, setGridDefaultColDef] = useState([]);
    const [budgetColDef, setBudgetColDef] = useState([]);
    const [showModal, setShowModal] = useState(false)
    const [toggleModal, setToggleModal] = useState(false)
    const [loading1, setLoading1] = useState(true);
    const [loading2, setLoading2] = useState(true);
    const [loading3, setLoading3] = useState(true);
    const [loading4, setLoading4] = useState(true);
    const [loading5, setLoading5] = useState(true);
    const [refreshPage, setRefreshPage] = useState(false);
    const { instance, accounts } = useMsal();

    const options = {
        style: 'currency',
        currency: 'GBP'
    };

    const data = {
        labels: bankLabels,
        datasets: [
            {
                label: 'Balance',
                backgroundColor: 'rgba(0, 128, 128, 0.8)',
                data: bankValues,
                fill: true,          // Don't fill area under the line
                datalabels: {
                    color: '#111',
                    // clamp: true,
                    // paddingBotton: '100px'
                    align: 'top',
                    anchor: 'end',
                    display: true,
                    formatter: function (value, context) {
                        return value
                            .toFixed(2)
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$&,');
                    },
                    font: {
                        weight: 'bold'
                    }
                    // padding: {
                    //     top: '10px'
                    // }
                }
            }
        ],
    }

    const data1 = {
        labels: investmentLabels,
        datasets: [
            {
                backgroundColor: '#00FF7F',
                borderColor: '#00FF7F',
                label: 'Cash',
                data: investmentValues,
                datalabels: {
                    display: false,
                }
            },
            {
                backgroundColor: '#40E0D0',
                borderColor: '#40E0D0',
                label: 'LifeStrategy Equity Fund',
                data: investmentValues1,
                datalabels: {
                    display: false,
                }
            },
            {
                backgroundColor: '#D2B48C',
                borderColor: '#D2B48C',
                label: 'FTSE Developed Europe UCITS ETF (VEUR)',
                data: investmentValues2,
                datalabels: {
                    display: false,
                }
            },
            {
                backgroundColor: '#4682B4',
                borderColor: '#4682B4',
                label: 'S&P 500 UCITS ETF (VUSA)',
                data: investmentValues3,
                datalabels: {
                    display: false,
                }
            },
            {
                backgroundColor: '#FF7F50',
                borderColor: '#FF7F50',
                label: 'Global Equity Fund - Accumulation',
                data: investmentValues4,
                datalabels: {
                    display: false,
                }
            },
        ],
    }

    const formatNumber = useCallback((number) => {
        // this puts commas into the number eg 1000 goes to 1,000,
        return (number)
            .toFixed(2)
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$&,');
    }, []);

    const currencyFormatter = useCallback((params) => {
        return formatNumber(params.value);
        // return params.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    }, [formatNumber]);

    const tokenRefresh = useCallback(async () => {
        await instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0]
        }).then((response) => {
            // console.log("token has been added:2")
            sessionStorage.setItem("upn", accounts[0].idTokenClaims.upn);
            sessionStorage.setItem("username", accounts[0].idTokenClaims.name);
            sessionStorage.setItem("authheader", "Bearer " + response.idToken);
            //console.log(response)
        }).catch((e) => {
            console.log("Error acquiring token")
        }
        );
    }, [accounts, instance])

    const openModal = () => {
        setShowModal(showModal => !showModal)
    }

    const closeModal = () => {
        setShowModal(showModal => false)
        setLoading1(true);
        setLoading2(true);
        setLoading3(true);
        setLoading4(true);
        setLoading5(true);
        setToggleModal(!toggleModal)
    }

    const initGridVar = useCallback(() => {
        setGridDefaultColDef({
            flex: 1,
            minWidth: 100,
            sortable: true,
            filter: true,
            resizable: false,
            editable: false,
            sortingOrder: ["asc", "desc"],
        });

        setBudgetColDef([

            {
                headerName: "Category",
                field: "category",
                // cellClass: "alignCenter widthhhh",
                suppressMovable: true,
                maxWidth: 150
            },
            {
                headerName: "Description",
                field: "cat_Desc",
                // cellClass: "alignCenter widthhhh",
                suppressMovable: true,
                maxWidth: 500
            },
            {
                headerName: "Amount",
                field: "total_Remaining",
                suppressMovable: true,
                filter: 'agNumberColumnFilter',
                type: 'numericColumn',
                maxWidth: 300,
                valueFormatter: currencyFormatter
            },
        ]
        );

        setTransColDef(
            [{
                headerName: "Date",
                field: "strDate",
                // sort: "asc",
                // cellClass: "alignCenter boldtext",
                suppressMovable: true,
                maxWidth: 125,
            },
            {
                headerName: "Category",
                field: "category",
                // cellClass: "alignCenter",
                suppressMovable: true,
                maxWidth: 125,

            },
            {
                headerName: "Desc",
                field: "reason1",
                // cellClass: "alignCenter widthhhh",
                suppressMovable: true,
                maxWidth: 500
            },
            {
                headerName: "Account",
                field: "bank",
                // cellClass: "alignCenter widthhhh",
                suppressMovable: true,
                maxWidth: 125
            },
            {
                headerName: "Amount",
                field: "value",
                suppressMovable: true,
                filter: 'agNumberColumnFilter',
                type: 'numericColumn',
                maxWidth: 300,
                valueFormatter: currencyFormatter
            },
            ]
        );
    }, [currencyFormatter]);



    useEffect(() => {
        initGridVar();
    }, [initGridVar]);

    useEffect(() => {
        const getFieldData = async () => {
            await base.getAll("Dashboard/FieldData").then((fieldRes) => {
                setFieldData(fieldRes);
                setLoading1(false)
            });
        }
        const getBudgetData = async () => {
            await base.getAll("Dashboard/Budget").then((budgetRes) => {
                setBudgetData(budgetRes);
                setLoading2(false)


            });
        }
        const getExpData = async () => {
            await base.getAll("Dashboard/ExpDet").then((expData) => {
                setExpData(expData);
                setLoading3(false)
            });
        }
        const getBankData = async () => {
            await base.getAll("Dashboard/Bank").then((bankRes) => {
                // setBankData(bankRes);
                setBankLabels(bankRes.map(function (item) {
                    return item.code;
                }));
                setBankValues(bankRes.map(function (item) {
                    return item.balance;
                }));
                setLoading4(false)

            });
        }
        const getInvestmentData = async () => {
            await base.getAll("Dashboard/Investment").then((investmentRes) => {

                setInvestmentLabels(investmentRes.filter(a => a.account_Code === "Cash").map(item => {
                    return item.strDate;
                }));

                setInvestmentValues(investmentRes.filter(a => a.account_Code === "Cash").map(item => {
                    return item.value;
                }));

                setInvestmentValues1(investmentRes.filter(a => a.account_Code === "LifeStrategy Equity Fund").map(item => {
                    return item.value;
                }));

                setInvestmentValues2(investmentRes.filter(a => a.account_Code === "FTSE Developed Europe UCITS ETF (VEUR)").map(item => {
                    return item.value;
                }));

                setInvestmentValues3(investmentRes.filter(a => a.account_Code === "S&P 500 UCITS ETF (VUSA)").map(item => {
                    return item.value;
                }));

                setInvestmentValues4(investmentRes.filter(a => a.account_Code === "Global Equity Fund - Accumulation").map(item => {
                    return item.value;
                }));

                setLoading5(false)

            });
        }
        // const callTokenRefresh = async () => {
        //     await tokenRefresh()
        // }
        tokenRefresh().then(() => {
            //console.log(loginRequest);
            // console.log(accounts[0]);

            getFieldData();
            getExpData();
            getBudgetData();
            getBankData();
            getInvestmentData();
        })

    }, [toggleModal, accounts, tokenRefresh, refreshPage])

    const handleClick = () => {
        window.open("https://client.moneyhub.co.uk/#");
    };

    const callRefresh = () => {
        // re-renders the component
        setLoading1(true);
        setLoading2(true);
        setLoading3(true);
        setLoading4(true);
        setLoading5(true);
        setRefreshPage(!refreshPage);
    };

    return (
        <>
            <div style={{ marginBottom: "80px" }} >
                {/* <input type="file" name="file" id="file" /> */}

                <CardHeader className="pt-3 pb-3">

                    <Row>
                        <Col xs="6" className="page-header">
                            Dashboard
                        </Col>
                        {/* <span > */}
                        <Col xs="6" className="text-end pe-4">
                            <Button size="sm" color="light border-dark" className="me-1 btn-text" onClick={() => callRefresh()}>
                                <img src={refresh} alt="refresh" />
                            </Button>
                            <Button size="sm" color="secondary border-dark" className="me-1 btn-text" onClick={() => openModal()}>
                                <img src={upload} alt="upload" />
                            </Button>
                            <Button size="sm" color="dark border-dark" className="btn-text" onClick={handleClick} >
                                <img src={pound} alt="pound" />
                            </Button>
                        </Col>
                        {/* </span> */}
                    </Row>
                </CardHeader>

                <>
                    <div className="pt-2 pb-2 res-padding scroll-container">
                        <Row>

                            <Col xs="12" lg="6">
                                <Card className="mt-2">
                                    <CardHeader className="cd-header">
                                        <img src={poundcircle} alt="pound" />&nbsp;<span className="mt-1">Budgets</span>
                                    </CardHeader>
                                    <CardHeader className="pt-1 pb-1 text-center">
                                        {loading1 ?
                                            <LoadSpinner />
                                            :
                                            <>
                                                <Row>
                                                    <Col className="text-muted cd-subheader">
                                                        Budget
                                                    </Col>
                                                    <Col className="text-muted cd-subheader">
                                                        Unallocated
                                                    </Col>
                                                    <Col className="text-muted cd-subheader">
                                                        Exceeded
                                                    </Col>
                                                </Row>
                                                <Row className="pt-2 pb-2">
                                                    <Col className="cd-value">
                                                        {fieldData.budgetTotal &&
                                                            fieldData.budgetTotal.toLocaleString('GB-en', options)
                                                        }
                                                    </Col>
                                                    <Col className="cd-value">
                                                        {fieldData.unallocated &&
                                                            fieldData.unallocated.toLocaleString('GB-en', options)
                                                        }
                                                    </Col>
                                                    <Col className="cd-value">
                                                        {fieldData.budgetExceeded &&
                                                            fieldData.budgetExceeded.toLocaleString('GB-en', options)
                                                        }
                                                    </Col>
                                                </Row>
                                            </>
                                        }
                                    </CardHeader>
                                    <CardBody style={{ height: "1200px" }}>
                                        {loading2 ?
                                            <LoadSpinner />
                                            :
                                            <div className="ag-theme-balham" style={{ height: "100%", width: "100%" }} >
                                                <AgGridReact
                                                    rowData={budgetData}
                                                    columnDefs={budgetColDef}
                                                    overlayNoRowsTemplate="No Records Found"
                                                    defaultColDef={gridDefaultColDef}
                                                    pagination={true}
                                                    paginationPageSize={50}>
                                                </AgGridReact>
                                            </div>

                                        }


                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xs="12" lg="6">
                                <Row>
                                    <Col xs="12">
                                        <Card className="mt-2">
                                            <CardHeader className="cd-header">
                                                <img src={cvc} alt="pound" />&nbsp;<span className="pt-1">Accounts</span>
                                            </CardHeader>
                                            <CardHeader className="pt-1 pb-1 text-center">
                                                {loading1 ?
                                                    <LoadSpinner />
                                                    :
                                                    <>
                                                        <Row>
                                                            <Col className="text-muted cd-subheader">
                                                                Total Balance
                                                            </Col>
                                                            <Col className="text-muted cd-subheader">
                                                                Current Accounts
                                                            </Col>
                                                            <Col className="text-muted cd-subheader">
                                                                Credit Cards
                                                            </Col>
                                                            <Col className="text-muted cd-subheader">
                                                                Savings
                                                            </Col>
                                                        </Row>
                                                        <Row className="pt-2 pb-2">
                                                            <Col className="cd-value">
                                                                {fieldData.bankTotal &&
                                                                    fieldData.bankTotal.toLocaleString('GB-en', options)
                                                                }
                                                            </Col>
                                                            <Col className="cd-value">
                                                                {fieldData.ddTotal &&
                                                                    fieldData.ddTotal.toLocaleString('GB-en', options)
                                                                }
                                                            </Col>
                                                            <Col className="cd-value">
                                                                {fieldData.ccTotal &&
                                                                    fieldData.ccTotal.toLocaleString('GB-en', options)
                                                                }
                                                            </Col>
                                                            <Col className="cd-value">
                                                                {fieldData.saTotal &&
                                                                    fieldData.saTotal.toLocaleString('GB-en', options)
                                                                }
                                                            </Col>

                                                        </Row>
                                                    </>
                                                }
                                            </CardHeader>
                                            <CardBody>
                                                {loading4 ?
                                                    <LoadSpinner />
                                                    :
                                                    <Bar data={data} style={{ maxHeight: "300px" }} />
                                                }
                                                {/* <AgChartsReact options={{
                                                        data: bankData,
                                                        theme: {
                                                            overrides: {

                                                            }
                                                        },
                                                        axes: [
                                                            {
                                                                type: 'category',
                                                                position: 'bottom'
                                                            },
                                                            {
                                                                type: 'number',
                                                                position: 'left',
                                                                label: {
                                                                    fontWeight: 'normal',
                                                                    rotation: -0
                                                                }, asdas 
                                                                gridStyle: [
                                                                    {
                                                                        lineDash: [4, 2],
                                                                        stroke: '#cecece'
                                                                    }
                                                                ]
                                                            }
                                                        ],
                                                        series: [
                                                            {
                                                                type: 'column',
                                                                fills: [
                                                                    'rgba(175, 175, 175, 0.8)'
                                                                ],
                                                                stroke: 'rgba(175, 175, 175, 0.8)',
                                                                strokeWidth: 0,
                                                                // lineDash: [6, 3],

                                                                xKey: ['code'],
                                                                yKeys: ['balance'],
                                                                label: {
                                                                    enabled: true,
                                                                    placement: 'outside',
                                                                },
                                                                highlightStyle: {
                                                                    item: {
                                                                        fill: 'rgba(75, 192, 192, 0.8)',
                                                                        stroke: 'black',
                                                                        strokeWidth: 2,
                                                                    },
                                                                },
                                                            },

                                                        ],
                                                        legend: {
                                                            enabled: false,

                                                        },

                                                    }
                                                    } /> */}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12">

                                        <Card className="mt-2">
                                            <CardHeader className="cd-header">
                                                <img src={grid} alt="pound" />&nbsp;<span className="pt-1">Transactions</span>
                                            </CardHeader>

                                            <CardBody style={{ height: "400px" }}>
                                                {loading3 ?
                                                    <LoadSpinner />
                                                    :
                                                    <div className="ag-theme-balham" style={{ height: "100%", width: "100%" }} >
                                                        <AgGridReact
                                                            rowData={expData}
                                                            columnDefs={transColDef}
                                                            overlayNoRowsTemplate="No Records Found"
                                                            defaultColDef={gridDefaultColDef}
                                                            pagination={true}
                                                            paginationPageSize={10}>
                                                        </AgGridReact>
                                                    </div>
                                                }
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12">

                                        <Card className="mt-2">
                                            <CardHeader className="cd-header">
                                                <img src={chart} alt="pound" />&nbsp;<span className="pt-1">Share ISA</span>
                                            </CardHeader>
                                            <CardHeader className="pt-1 pb-1 text-center">
                                                {loading1 ?
                                                    <LoadSpinner />
                                                    :
                                                    <>
                                                        <Row>
                                                            <Col className="text-muted cd-subheader">
                                                                Invested
                                                            </Col>
                                                            <Col className="text-muted cd-subheader">
                                                                Profit Value
                                                            </Col>
                                                            <Col className="text-muted cd-subheader">
                                                                Profit %
                                                            </Col>
                                                        </Row>
                                                        <Row className="pt-2 pb-2">
                                                            <Col className="cd-value">
                                                                {fieldData.invested &&
                                                                    fieldData.invested.toLocaleString('GB-en', options)
                                                                }
                                                            </Col>
                                                            <Col className="cd-value">
                                                                {fieldData.profit &&
                                                                    fieldData.profit.toLocaleString('GB-en', options)
                                                                }
                                                            </Col>
                                                            <Col className="cd-value">
                                                                {fieldData.profitPercent &&
                                                                    fieldData.profitPercent.toLocaleString()
                                                                }
                                                            </Col>
                                                        </Row>
                                                    </>
                                                }
                                            </CardHeader>
                                            <CardBody>
                                                {loading5 ?
                                                    <LoadSpinner />
                                                    :
                                                    < Line data={data1} style={{ maxHeight: "300px" }} />
                                                }

                                                {/* <AgChartsReact options={{
                                                        data: investmentData,
                                                        theme: {
                                                            overrides: {

                                                            }
                                                        },
                                                        axes: [
                                                            {
                                                                type: 'category',
                                                                position: 'bottom'
                                                            },
                                                            {
                                                                type: 'number',
                                                                position: 'left',
                                                                label: {
                                                                    fontWeight: 'normal',
                                                                    // rotation: 224
                                                                },
                                                                gridStyle: [
                                                                    {
                                                                        lineDash: [4, 2],
                                                                        stroke: '#cecece'
                                                                    }
                                                                ]
                                                            }
                                                        ],
                                                        series: [
                                                            {
                                                                xKey: 'strDate',
                                                                yKey: 'value',
                                                                yName: 'Investment value',
                                                                stroke: 'black',
                                                                marker: {
                                                                    fill: 'gray',
                                                                    stroke: 'black',
                                                                },
                                                            },
                                                        ],
                                                        legend: {
                                                            enabled: false,

                                                        },

                                                    }
                                                    } /> */}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                    </div>
                </>


                {showModal && <FileModal closeModal={closeModal} showModalForm={true} tokenRefresh={tokenRefresh} />}
            </div >

        </>
    );
}

export default Dashboard;
