import React from 'react'
// import logo from './logo.svg';
import { useEffect, useState, useCallback, useRef } from 'react';
import '../App.css';
import '../Assets/css/Main.css'
import { Col, Row, CardHeader, CardBody, Button } from 'reactstrap';
// import DataTable from 'react-data-table-component';
import add from '../Assets/icons/addlrg24.svg'
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import base from '../Services/BaseService'
import LoadSpinner from './Shared/Loading'
import { useMsal } from "@azure/msal-react";
import { loginRequest } from '../authconfig';
import InvestmentModal from './InvestmentModal'

const Investment = () => {

    const gridRef = useRef();
    const [investmentData, setInvestmentData] = useState([]);
    const [saveItem, setSaveItem] = useState();
    const [investmentID, setInvestmentID] = useState();
    const [gridDefaultColDef, setGridDefaultColDef] = useState([]);
    const [budgetColDef, setBudgetColDef] = useState([]);
    const [showModal, setShowModal] = useState(false)
    const [toggleModal, setToggleModal] = useState(false)
    const [expLoading, setExpLoading] = useState(true);
    const { instance, accounts } = useMsal();
    const [isEdit, setIsEdit] = useState(false);

    const formatNumber = useCallback((number) => {
        // this puts commas into the number eg 1000 goes to 1,000,
        return (number)
            .toFixed(2)
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$&,');
    }, []);
    const currencyFormatter = useCallback((params) => {
        return formatNumber(params.value);
        // return params.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    }, [formatNumber]);


    // const formatDate = useCallback((number) => {
    //     // this puts commas into the number eg 1000 goes to 1,000,
    //     // return (number)
    //     //     .format('dd-m-yy')
    //     return (new Intl.DateTimeFormat('en-GB', number))
    //     // .toLocaleFormat('%d-%b-%Y');
    // }, []);

    // const dateFormatter = useCallback((params) => {
    //     return formatDate(params.value);
    //     // return params.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    // }, [formatDate]);



    const initGridVar = useCallback(() => {
        setGridDefaultColDef({
            flex: 1,
            minWidth: 100,
            sortable: true,
            filter: true,
            resizable: false,
            editable: false,
            sortingOrder: ["asc", "desc"],
            // valueFormatter: dateFormatter,

        });

        setBudgetColDef([
            {
                headerName: "Post Date",
                field: "post_Date",
                // cellClass: "alignCenter widthhhh",
                suppressMovable: true,
                maxWidth: 200
            },
            {
                headerName: "Account Code",
                field: "account_Code",
                // cellClass: "alignCenter widthhhh",
                suppressMovable: true,
                maxWidth: 200
            },
            {
                headerName: "Account Name",
                field: "account_Name",
                // cellClass: "alignCenter widthhhh",
                suppressMovable: true,
                maxWidth: 200
            },
            {
                headerName: "Fund Name",
                field: "fund_Name",
                // cellClass: "alignCenter widthhhh",
                suppressMovable: true,
                maxWidth: 200
            },
            {
                headerName: "Amount Invested",
                field: "book_Cost",
                suppressMovable: true,
                filter: 'agNumberColumnFilter',
                type: 'numericColumn',
                maxWidth: 350,
                valueFormatter: currencyFormatter
            },
            {
                headerName: "Fund Price",
                field: "fund_Price",
                suppressMovable: true,
                filter: 'agNumberColumnFilter',
                type: 'numericColumn',
                maxWidth: 350,
                valueFormatter: currencyFormatter
            },
            {
                headerName: "Fund Valuation",
                field: "fund_Valuation",
                suppressMovable: true,
                filter: 'agNumberColumnFilter',
                type: 'numericColumn',
                maxWidth: 350,
                valueFormatter: currencyFormatter
            },
            {
                headerName: "id",
                field: "id",
                // hidden: true,
                suppressMovable: true,
                hide: true,
                // filter: 'agNumberColumnFilter',
                type: 'numericColumn',
                // setColumnVisible: false,
                // valueFormatter: currencyFormatter
            },
        ]
        );
    }, [currencyFormatter]);


    const openModal = () => {
        setShowModal(!showModal)
        // console.log(showModal)
    }

    const closeModal = () => {
        setShowModal(showModal => false)
        // setExpLoading(true)
        setToggleModal(!toggleModal)
    }

    useEffect(() => {
        initGridVar();
    }, [initGridVar]);

    const tokenRefresh = useCallback(async () => {
        await instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0]
        }).then((response) => {
            // console.log("token has been added:2")
            sessionStorage.setItem("upn", accounts[0].idTokenClaims.upn);
            sessionStorage.setItem("username", accounts[0].idTokenClaims.name);
            sessionStorage.setItem("authheader", "Bearer " + response.idToken);
            //console.log(response)
        }).catch((e) => {
            console.log("Error acquiring token")
        }
        );
    }, [accounts, instance])

    useEffect(() => {
        const getInvestmentData = async () => {
            await base.getAll("Investment").then((investmentRes) => {
                setInvestmentData(investmentRes);
            });
        }
        tokenRefresh().then(() => {
            getInvestmentData();
            setTimeout(() => {
                setExpLoading(false)
            }, [1000])
        })

    }, [saveItem, tokenRefresh])

    const handleClick = () => {
        // window.open("https://app.moneydashboard.com/login");
        // console.log("create")
        setIsEdit(false);
        openModal();
    };

    const onSelectionChanged = () => {
        const selectedRows = gridRef.current.api.getSelectedRows();
        if (selectedRows.length === 1) {
            setInvestmentID(selectedRows[0].id);
            setIsEdit(true);
            openModal();
            // console.log(selectedRows[0].id)
            // setTimeout(() => {
            //     console.log(budgetID);
            // }, [5000])

        }
        // document.querySelector('#selectedRows').innerHTML =
        //     selectedRows.length === 1 ? selectedRows[0].id : '';
    };

    return (
        <>
            {/* <Row className="grid-margin">
                <Col xs="12"> */}
            <CardHeader className="pt-3 pb-3">
                <Row>
                    <Col xs="6" className="page-header">
                        Investments
                    </Col>
                    {/* <span > */}
                    <Col xs="6" className="text-end pe-4">
                        <Button size="sm" color="dark" className="btn-text" onClick={handleClick} >
                            <img src={add} alt="add" />
                        </Button>
                    </Col>
                    {/* </span> */}
                </Row>
            </CardHeader>
            {expLoading ?
                <LoadSpinner />
                :
                <div style={{ marginBottom: "80px" }}>
                    <div className="scroll-container">
                        {/* <div className="example-header">
                                Selection:
                                <span id="selectedRows"></span>
                            </div> */}

                        <CardBody style={{ height: "700px" }}>
                            <div className="ag-theme-balham" style={{ height: "100%", width: "100%" }} >
                                <AgGridReact
                                    ref={gridRef}
                                    rowData={investmentData}
                                    columnDefs={budgetColDef}
                                    overlayNoRowsTemplate="No Records Found"
                                    defaultColDef={gridDefaultColDef}
                                    pagination={true}
                                    // onGridReady={onGridReady}
                                    rowSelection={'single'}
                                    // rowSelection={'single'}
                                    paginationPageSize={20}
                                    // gridOptions={gridOptions}
                                    // onSelectionChanged={onSelectionChanged}
                                    onRowDoubleClicked={onSelectionChanged}

                                // onGridReady={onGridReady}
                                // onSelectionChanged={gridRef.current.api.getSelectedRows()}
                                // onRowDoubleClicked={() => console.log('Row double clicked')}
                                // onRowDoubleClicked=(()=> editRow())
                                >
                                </AgGridReact>
                            </div>
                        </CardBody>
                    </div>
                </div>
            }
            {/* 
                </Col>

            </Row > */}
            {showModal && <InvestmentModal id={investmentID} closeModal={closeModal} showModalForm={true} tokenRefresh={tokenRefresh} setSaveItem={setSaveItem} isEdit={isEdit} />}
            {/* {showModal && <BudgetModal id={budgetID} openModal={openModal} closeModal={closeModal} showModalForm={true} />} */}

        </>
    )
}

export default Investment
