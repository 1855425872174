import { useEffect } from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, useIsAuthenticated } from "@azure/msal-react";
import { InteractionStatus } from '@azure/msal-browser';
import Routes from './Components/Shared/Routes'
import LoadSpinner from './Components/Shared/Loading'

const App = () => {

  // const recaptchaRef = useRef("");
  // const [recaptchaValue, setrecaptchaValue] = useState("");
  const { instance, accounts, inProgress } = useMsal();
  const name = accounts[0] && accounts[0].name;
  const isAuthenticated = useIsAuthenticated();

  // function onChange(value) {
  //   console.log("Captcha value:", value);
  // }

  // const onSubmitWithReCAPTCHA = async () => {
  //   console.log("ref", recaptchaRef.current.getValue());
  //   if (recaptchaRef.current.getValue() !== "") {
  //     if (!isAuthenticated && inProgress === InteractionStatus.None) {
  //       instance.handleRedirectPromise()
  //         .then((tokenResponse) => {
  //           if (!tokenResponse) {
  //             const accounts = instance.getAllAccounts();
  //             if (accounts.length === 0) {
  //               // No user signed in
  //               instance.loginRedirect();
  //             }
  //           } else {
  //             // Do something with the tokenResponse
  //           }
  //         })
  //         .catch(err => {
  //           // Handle error
  //           console.error(err);
  //         });
  //     }
  //   }
  //   else {
  //     alert("Please use reCAPTCHA!")
  //   }
  // }

  useEffect(() => {
    setTimeout(() => {
      if (!isAuthenticated && inProgress === InteractionStatus.None) {
        instance.handleRedirectPromise()
          .then((tokenResponse) => {
            if (!tokenResponse) {
              const accounts = instance.getAllAccounts();
              if (accounts.length === 0) {
                // No user signed in
                instance.loginRedirect();
              }
            } else {
              // Do something with the tokenResponse
            }
          })
          .catch(err => {
            // Handle error
            console.error(err);
          });
      }
    }, 500);
  });

  return (
    <>
      <AuthenticatedTemplate>
        <Routes name={name} />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <LoadSpinner />
        {/* <h1>User Not logged in</h1>
        <p><Button variant="secondary" className="ml-auto" onClick={() => onSubmitWithReCAPTCHA()}>Sign in using Redirect</Button></p>
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey="6Lc3j9weAAAAABl7GRky-PUwxXr2iQZnMDzFlOTZ"
          onChange={onChange}
        />, */}
      </UnauthenticatedTemplate>
    </>
  );
}

export default App;


