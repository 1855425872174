import '../../Assets/css/Footer.css'
import { Row, Col } from 'reactstrap'

const Footer = (props) => {
    return (
        <div className="footer fixed-bottom pt-3 pb-3">
            <Row>
                <Col xs="3" className="ms-2 footer-text">
                    {props.name}
                </Col>
                <Col xs="6" className="text-center footer-text">
                    Mobyte Ltd - Copyright © {new Date().getFullYear()}.
                </Col>
                <Col xs="3">
                    {/* Test */}
                </Col>
            </Row>
        </div>
    );
}

export default Footer