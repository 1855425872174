import { Spinner } from "reactstrap"
import '../../Assets/css/Main.css';


const Loader = () => {
    return (
        <>
            <div className="col-12">
                <div className="loadspinner">
                    <Spinner style={{ width: '3rem', height: '3rem' }} />
                </div>
            </div>
        </>
    )
}

export default Loader


