const HttpsRedirect = ({ children }) => {
  if (
    process.env.NODE_ENV === 'production' &&
    process.env.REACT_APP_HTTPS_REDIRECT_DISABLED !== true &&
    typeof window !== 'undefined' &&
    window.location &&
    window.location.protocol === 'http:'
  ) {
    window.location.href = window.location.href.replace(/^http(?!s)/, 'https');
    return null;
  }

  return children;
};

export default HttpsRedirect;
