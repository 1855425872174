import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import Dashboard from '../Dashboard'
import Category from '../Category'
import Investment from '../Investment'
import Budget from '../Budget'
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom'
// import PrivateRoutes from './PrivateRoutes'
// import base from '../../Services/BaseService'
// import { useMsal } from "@azure/msal-react";
// import { loginRequest } from '../../authconfig';

// import UserError from '../UserError';

const PrivateRoute = ({ name }) => {
    // const { instance, accounts } = useMsal();
    // const [error, setError] = useState(false)



    return (
        <>

            <Router basename='/'>
                {/* {error &&
                    <Navigate to='/usererror' />
                } */}
                <>
                    <Navbar />
                    <Routes>
                        <Route path="/" element={<Dashboard />} />
                        <Route path="/budget" element={<Budget />} />
                        <Route path="/category" element={<Category />} />
                        <Route exact path='/investment' element={<Investment />} />

                    </Routes>

                </>
                <Footer name={name} />

            </Router>
        </>
    )
}

export default PrivateRoute
